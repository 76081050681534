font-face {
  font-family: 'DELIRIUM NCV';
  src: url('../../assets/font/DELIRIUM-NCV/DELIRIUM NCV.ttf');
  font-style: normal;
}

.customBox {
  background-color: #1f2436;
  position: relative;
  border-radius: 8px;
}
.customBox::before {
  content: '';
  position: absolute;
  z-index: -1;
  // background-color: blue;
  width: 101%;
  height: 111%;
  left: 0%;
  top: -10%;
  border-radius: 8px;
  background: linear-gradient(300deg, #4f81fd, #c03dfd);
}
.athleteBox {
  position: relative;
  &:after {
    content: '';
    clip-path: polygon(0 13%, 100% 0%, 100% 100%, 0% 100%);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    background: transparent linear-gradient(139deg, #4f81fd 0%, #0d102d 35%);
    z-index: -1;
  }
}
.tokenImage {
  background-image: url('../../assets/images/clubPlayer.png');
  // background-position: left, right;
  background-repeat: no-repeat;
  background-size: contain;
}
