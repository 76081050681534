@for $size from 10 through 96 {
  .fs--#{$size} {
    font-size: $size/16 + rem !important;
  }
}
$weights: 100 200 300 400 500 600 700 800;
@each $weight in $weights {
  .fw--#{$weight} {
    font-weight: $weight !important;
  }
}
.hoverCard {
  background-color: #1f2436;
  position: relative;
  border-radius: 8px;
  transition: 0.5s all;
}
.hoverCard::before {
  content: '';
  position: absolute;
  transition: 0.5s all;
  top: -4%;
  left: 0%;
  width: 101%;
  height: 104%;
  z-index: -1;
  background: linear-gradient(300deg, #4f81fd, #c03dfd);
  border-radius: 8px;
}
.customBoxButton {
  position: absolute;
  bottom: 0%;
  visibility: hidden;
}
.hoverCard:hover::before {
  top: -0.5%;
  height: 100%;
}
.hoverCard:hover .customBoxButton {
  visibility: visible;
  bottom: 14%;
  transition: 0.5s all;
}
.NFT-image {
  position: relative;
}
.NFT-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(to bottom, transparent 0%, black 100%);
}
.gradient-mask {
  position: relative;
}
.gradient-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // border-radius: 0 0 6px 6px;
  border-bottom: 1px solid transparent;
  background: linear-gradient(90deg, #c03dfd, #4f81fd) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.glass-effect {
  /* From https://css.glass */
  background: rgba(15, 15, 18, 0.66);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.6px);
  -webkit-backdrop-filter: blur(13.6px);
  border: 1px solid rgba(15, 15, 18, 0.52);
}
.glass-effect-2 {
  /* From https://css.glass */
  background: #2f3852;
  border-radius: 12px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border: 1px solid #2f3852;
}
.glass-effect-drawer {
  /* From https://css.glass */
  background: #2f3852;
  // border-left: 0px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  // border-right: 1px solid #2f3852;
}
.hoverCard:hover .customBoxButton {
  visibility: visible;
  bottom: 14%;
}
.gradient-border-mask {
  position: relative;
}
.gradient-border-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  background: linear-gradient(45deg, #4f81fd, #00fd42) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.gradient-card-border {
  position: relative;
}
.gradient-card-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px 6px 0px 0px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  background: linear-gradient(90deg, #c03dfd, #4f81fd, #c03dfd) border-box;
  clip-path: polygon(
    0% 0%,
    0% 100%,
    0 91%,
    1% 13%,
    99% 14%,
    100% 100%,
    100% 100%,
    100% 88%,
    100% 0%
  );

  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.gradient-club-card-border {
  position: relative;
}
.gradient-club-card-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  border: 2px solid transparent;
  background: linear-gradient(45deg, #fdff1d, #4bff79, #4f81fd, #c03dfd)
    border-box;

  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.slick-list {
  margin: 0 -15px;
}
// .clubBG{
//   background-image: url('../images/myAccBgImg.png'), url('../images/CollectionMainBg.png');
//   background-position: cover ,cover;
//   background-repeat: no-repeat, no-repeat;
//   background-size: contain;
// }
