@font-face {
  font-family: 'Gotham';
  src: url('./assets/font/Gotham/Gotham-Light.otf');
  font-style: normal;
}
body {
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: initial;
  }
}
/* For webkit browsers */
::-webkit-scrollbar {
  width: 0px;
}
// @font-face {
//   font-family: 'Gotham';
//   src: url('./assets/font/Gotham/Gotham-Book.otf');
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Gotham';
//   src: url('./assets/font/Gotham/Gotham-Bold.otf');
//   font-weight: bold;
// }
// @font-face {
//   font-family: 'Gotham';
//   src: url('./assets/font/Gotham/Gotham-Medium.otf');
//   font-style: normal;
// }
